<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <div class="text-2xl text-primary font-bold">
                      Add New Web Content
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-8 sm:col-12 comp-grid">
            <div :class="{ card: !isSubPage }" class="">
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startSaveRecord()"
                >
                  <div class="grid">
                    <div class="col-12">
                      <div class="formgrid grid">
                        <div class="col-12 md:col-3">Title *</div>
                        <div class="col-12 md:col-9">
                          <Textarea
                            :class="getErrorClass('title')"
                            class="w-full"
                            ref="ctrltitle"
                            rows="5"
                            v-model="formData.title"
                            placeholder="Title"
                            type="textarea"
                          >
                          </Textarea>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="formgrid grid">
                        <div class="col-12 md:col-3">Date to Show *</div>
                        <div class="col-12 md:col-9">
                          <Calendar
                            :showButtonBar="true"
                            class="w-full"
                            :class="getErrorClass('datetoshow')"
                            dateFormat="yy-mm-dd"
                            v-model="formData.datetoshow"
                            :showIcon="true"
                            mask="YYYY-MM-DD"
                            outlined
                            dense
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="showSubmitButton" class="text-center my-3">
                    <Button
                      class="p-button-primary"
                      type="submit"
                      label="Submit"
                      icon="pi pi-send"
                      :loading="saving"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addWebcontentPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
  },
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "webcontent",
    },
    routeName: {
      type: String,
      default: "webcontentadd",
    },
    apiPath: {
      type: String,
      default: "webcontent/add",
    },
  },
  data() {
    return {
      formData: {
        title: "",
        datetoshow: "",
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Add Web Content";
      },
    },
  },
  validations() {
    let formData = {
      title: { required, maxLength: maxLength(75) },
      datetoshow: { required },
    };
    return { formData };
  },
  methods: {
    ...mapActions("webcontent", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Form is invalid", "", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.record = response.data ?? {};
          this.id = this.record["postid"] ?? null;
          this.saving = false;
          this.resetForm();
          this.submitted = false;
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          if (this.redirect) this.navigateTo(`/webcontent`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    resetForm() {
      this.formData = {
        title: "",
        datetoshow: "",
      };
      this.updateFormData();
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      return {
        "p-invalid": this.v$.formData[field].$invalid && this.submitted,
      };
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
